import React, { useEffect, useCallback } from "react";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import dayjs from "dayjs";

const logo =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAYAAABXuSs3AAAABHNCSVQICAgIfAhkiAAABF1JREFUaEPtmV9WGjEUxm84vhd0AeIOcAXiCkoP9lnoBqwrcFxBcQOCz4XD7EBYgbgDXICOvtdJv5tkhnTKwISM2J5jzhkFJn9++XKTe5MIKjEFrX4T1Z3haeCpm6ongiiURDdB2H0uqznU6Z8AXEUtYzwMnpcYugv40L9FIm9wA32rVZYPqDLA5zBRF+/5d/wmPhtghh/4wpcAfg0FGUre438zzxzQgQ5g+3hY+UPkm/vAe4FrNelOknwRJOrrbBj5e8iPOSBh79+4IxsnX3ADQueA5s8rkzGriDMhv1fbXoWD1vUE5nFkhn62DlwBL8ocAx7lN0ulgLuotzAX+u/AA2h8gecD3NnYEnt1NJV/QfF+Hb2tArzQxNSTs//+4M5D9AFeQqziqvrbmco4alAcn5IU7M5LSOKKTmppRJgLPnoM0CY7MzshrhEhyt9kQRYOaBxV6VX2ES+2SqC1qhDn1K6l4UA++BN70Sx4Us+cYtGlr7XU02pwDX0L6AZJeiEhexRXJnbGsjpT2FTGUZ1iju9lZ9EhwLdrA2bR4KO0t/dUEU36Uittp5LtcGFwu+Ao+o4O/FA/VcQh+GaCfkZNqkBtogf82HhLaG53I3AlLuYAiQtYxIROdo8FDZ9C6I6NwGIYXMyCQ9V1cbhd38bg2jLm+LsPgQ+E9aXmqjZcPu9+jgBeK9pZL/DhY4+EOMNEheKjJ2zAkdq7ziHu1mOVxFxIXrKpPMNUPr0pOPsGTChj4+wfeKUIXExMLyLGzkmcs+IT/HSUzNaiQ64h9A5oZXSYTH4p4Yj2sDp4pIRVm4pZaqS8QcWsROFUCHz4OIBdnqpKMamgPE8w95QKAD9zslsVxvnMlbk4rixrwdmxxVJtjlXaQBxVznaQsG9q7wV6Qg6jFrwln0Qh4UCnQldFVpi14Onai9EkgTYgjqvqrLSA82GvTjTFXGwqylSNUdSBIlhuWHlWBwu9kJhQlYUXzXRoKbgK0tgvqEo6+FNXsHGMz+xAjEmq0VDnjMuTlA0DWzcZpqinlQj65xLI8cFrHKQ2ma0Sk8KOX5aCpw4tLaxV0sOtTVJ1RA6QIy+oslvWx3omRkle5K/dPETZZEVn/CpH8Tr9Sk9qiXZolppdupzRXI0E0T2cSf5Ks4N8OZPZ2enYfVlr49mO26rzOxMw5ZrLihfbBWcQy/vx6rAJNJfZPji3ymaYMTvXDrwPuCvlkvwf4EVFRFjLqwifHXod7m9dca943FKnFHDUd1D0asRsPtizvutprbn/UbdpgyLmAnA4H7Hv0tll9Xoqnl5ITQB+vA7c3INiYy4fcAfEnnPj5AXOrVoKrrwHMvc/dyjCwIVHKK9nJYCr22Q+3mBvFlyE3ctsY+Z2jq8K+c5zCrW5jFfyBteqpybDX2HDFKJiFQ5jJ86QBlROOS533msu6WIp4Aae1cQZ4V8Hl4BX96A9AAdeMluFSwNP6oT6bMO8W+GHVZ/5XAvmdfQ3oW5I5Uzz8CkAAAAASUVORK5CYII=";

let organization = JSON.parse(localStorage.getItem('organizationDetails'))

const documentDefinition = {
    pageSize: {
        width: 595,
        height: 500,
    },
    content: [
        {
            columns: [
                {
                    width: '20%',
                    image: 'logo',
                    width: 35,
                    margin: [0, 0, 0, 0],
                    alignment: "center"
                },
                {
                    width: '80%',
                    stack: [
                        {
                            text: organization?.orgName,
                            style: 'subHeaderTitle',
                        },
                        {
                            text: organization?.orgEmail,
                            style: 'normalText',
                        },
                        {
                            text: organization?.orgAddress,
                            style: 'normalText',
                        },
                    ],
                    margin: [0, 0, 10, 0]
                }
            ],
            columnGap: 20
        },
        {
            table: {
                widths: ["100%"],
                body: [
                    [
                        {
                            border: [false, true, false, false],
                            text: "",
                            alignment: "center",
                            fontSize: 10,
                        },
                    ],
                ],
            },
            margin: [0, 10, 0, 10],
        },
    ],
    footer: function (currentPage, pageCount) {
        return {
            columns: [

                {
                    width: '20%',
                    alignment: 'left',
                    stack: [
                        {
                            image: logo,
                            width: 25,
                            margin: [40, 0, 0, 2],
                        },
                    ],
                    margin: [0, 0, 10, 0]
                },
                {
                    width: '*',
                    alignment: 'right',
                    stack: [
                        {
                            text: 'PULSE EMR',
                            style: 'normalText',
                            margin: [40, 10, 0, 2],
                        },
                    ],
                    margin: [0, 0, 10, 0]
                },
            ],
            margin: [0, 0, 10, 20]
        };
    },
    styles: {
        tableStyle: {
            fontSize: 9,
            alignment: "center",
        },
        tableHeader: {
            bold: true,
            fontSize: 9,
            color: "black",
            alignment: "center",
            fillColor: "#e5e5e5",
        },
        stackStyle: {
            border: "1px solid #cccccc",
        },
    },
    images: {
        logo: organization?.orgLogo,
    },
};

const generateDynamicMessage = (attributes) => {
    const {
        startDate,
        endDate,
        startAge,
        endAge,
        gender,
        diseaseId,
    } = attributes;

    let messageParts = [];

    if (diseaseId) {
        messageParts.push(`for disease ${diseaseId}`);
    }

    if (gender) {
        messageParts.push(`for gender ${gender}`);
    }

    if (startAge && endAge) {
        messageParts.push(`in age range ${startAge} to ${endAge}`);
    } else if (startAge) {
        messageParts.push(`for age ${startAge} and above`);
    } else if (endAge) {
        messageParts.push(`for age up to ${endAge}`);
    }

    if (startDate && endDate) {
        messageParts.push(`from ${dayjs(startDate).format('DD-MM-YYYY')} to ${dayjs(endDate).format('DD-MM-YYYY')}`);
    } else if (startDate) {
        messageParts.push(`from ${dayjs(startDate).format('DD-MM-YYYY')} onwards`);
    } else if (endDate) {
        messageParts.push(`up to ${dayjs(endDate).format('DD-MM-YYYY')}`);
    }

    const dynamicMessage = messageParts.length > 0
        ? `The report is generated ${messageParts.join(", ")}.`
        : "The report includes all available data.";

    return dynamicMessage;
};

const DiseaseReportPdf = () => {
    const createPdf = (documentDefinition) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(documentDefinition).download(`diseaseReportPdf.pdf`);
        setTimeout(function () {
            window.close();
        }, 1000);
    };

    const executePrint = (callbackAttributes, reportData) => {
        const noteMessage = generateDynamicMessage(callbackAttributes);

        // Reset documentDefinition for each new PDF generation
        let documentDefinition = {
            pageSize: {
                width: 570,
                height: 450,
            },
            content: [
                {
                    columns: [
                        {
                            width: '20%',
                            image: 'logo',
                            width: 35,
                            margin: [0, 0, 0, 0],
                            alignment: "center"
                        },
                        {
                            width: '80%',
                            stack: [
                                {
                                    text: organization?.orgName,
                                    style: 'subHeaderTitle',
                                },
                                {
                                    text: organization?.orgEmail,
                                    style: 'normalText',
                                },
                                {
                                    text: organization?.orgAddress,
                                    style: 'normalText',
                                },
                            ],
                            margin: [0, 0, 10, 0]
                        }
                    ],
                    columnGap: 20
                },
                {
                    table: {
                        widths: ["100%"],
                        body: [
                            [
                                {
                                    border: [false, true, false, false],
                                    text: "",
                                    alignment: "center",
                                    fontSize: 10,
                                },
                            ],
                        ],
                    },
                    margin: [0, 10, 0, 10],
                },
            ],
            styles: {
                tableStyle: {
                    fontSize: 9,
                    alignment: "center",
                },
                tableHeader: {
                    bold: true,
                    fontSize: 9,
                    color: "black",
                    alignment: "center",
                    fillColor: "#e5e5e5",
                },
                stackStyle: {
                    border: "1px solid #cccccc",
                },
            },
            footer: function (currentPage, pageCount) {
                return {
                    columns: [

                        {
                            width: '20%',
                            alignment: 'left',
                            stack: [
                                {
                                    image: logo,
                                    width: 25,
                                    margin: [40, 0, 0, 2],
                                },
                            ],
                            margin: [0, 0, 10, 0]
                        },
                        {
                            width: '*',
                            alignment: 'right',
                            stack: [
                                {
                                    text: 'PULSE EMR',
                                    style: 'normalText',
                                    margin: [40, 10, 0, 2],
                                },
                            ],
                            margin: [0, 0, 10, 0]
                        },
                    ],
                    margin: [0, 0, 10, 20]
                };
            },
            images: {
                logo: organization?.orgLogo,
            },
        };

        let note = {
            columns: [
                {
                    width: "100%",
                    stack: [
                        {
                            text: noteMessage,
                            style: "tableHeader",
                            alignment: "left",
                            margin: [0, 0, 0, 10], // Add bottom margin here
                        },
                    ],
                },
            ],
            columnGap: 10,
        };

        let noDataMessage = {
            columns: [
                {
                    width: "100%",
                    stack: [
                        {
                            text: "No records found",
                            style: "tableHeader",
                            alignment: "center",
                            margin: [10, 0, 0, 10], // Add bottom margin here
                        },
                    ],
                },
            ],
            columnGap: 10,
        };

        let table1Content = [];

        if (reportData?.length > 0) {
            table1Content.push([
                { text: "Disease Name", style: "tableHeader", alignment: "left" },
                { text: "Total Case", style: "tableHeader", alignment: "left" },
            ]);
            reportData.map((item) => {
                table1Content.push([
                    {
                        text: item?.diseaseName,
                        style: "tableStyle",
                        alignment: "left",
                    },
                    {
                        text: item?.totalCases.toString(),
                        style: "tableStyle",
                        alignment: "left",
                    },
                ]);
            });

            let mainBody = {
                columns: [
                    {
                        width: "100%",
                        table: {
                            widths: ["80%", "20%"],
                            body: table1Content,
                        },
                    },
                ],
                columnGap: 10,
            };

            documentDefinition.content.push(note);
            documentDefinition.content.push(mainBody);
        } else {
            documentDefinition.content.push(noDataMessage);
        }

        createPdf(documentDefinition);
    };

    useEffect(() => {
        let pdfData = localStorage.getItem("diseaseReportData");
        let parsedData = JSON.parse(pdfData);
        executePrint(parsedData?.callbackAttributes, parsedData?.reportData);
    }, []);

    return <div></div>;
};

export default DiseaseReportPdf;
