import React, { useState, useEffect, useRef, createRef } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../../components/dropdown";
import TextArea from "../../../components/textArea";
import Button from "../../../components/button";
import TextInput from "../../../components/textInput";
import { addNewOpdAppointment } from "../../../services/opdAppointment";
import { OtherFeesSection } from "./util";
import { getPatientById } from "../../../services/patient";
import { getDoctorById } from "../../../services/doctor";
import Modal from "../../../components/modal";
import SuccessBtn from "../../../components/successBtn";
import { db } from "../../../services/fireBase/fireBaseServices";
import { addDoc, arrayUnion, collection, deleteDoc, getDocs, query, serverTimestamp, updateDoc, where } from "firebase/firestore";

const paymentModeOptions = [
    {
        label: "Cash",
        value: "Cash",
    },
    {
        label: "Cheque",
        value: "Cheque",
    },
];

const PaymentInformation = (props) => {
    const notificationsCollection = collection(db, 'notifications');
    const appointmentCollection = collection(db, 'appointments');

    const navigate = useNavigate();
    const [otherFeesOptions, setOtherFeesOptions] = useState([]);
    const [otherFees, setOtherFees] = useState([]);
    const [info, setInfo] = useState({
        fees: "",
        paymentMode: "Cash",
        transactionId: "",
        remarks: "",
        otherFees1: "0",
        otherFees2: "0",
        otherFees3: "0",
        otherFees4: "0",
        insuranceDetailsJson: {
            insuranceCompany: "",
            policyNumber: "",
            groupNumber: ""

        },
        feesDetailsJson: {
            otherFees1: "0",
            otherFees2: "0",
            otherFees3: "0",
            otherFees4: "0",
            totalFees: "0",
            paymentMode: "Cash",
            transactionId: ""
        }
    });
    const [totalFee, setTotalFee] = useState(0);
    const [isModalVisible, setModalVisible] = useState(false);
    const [insuranceScreen, setInsuranceScreen] = [props.insuranceScreen, props.setInsuranceScreen];
    const openModal = () => {
        setModalVisible(true)
    };
    const closeModal = () => setModalVisible(false);
    useEffect(() => {
        if (props.info) {
            const appointmentInfo = props.info;
            setInfo({
                fees: appointmentInfo.hasOwnProperty("fees") ? appointmentInfo.fees : 0,
                paymentMode: appointmentInfo.hasOwnProperty("paymentMode") ? appointmentInfo.paymentMode : "Cash",
                transactionId: appointmentInfo.hasOwnProperty("transactionId") ? appointmentInfo.transactionId : "",
                remarks: appointmentInfo.hasOwnProperty("remarks") ? appointmentInfo.remarks : "",
                insuranceDetailsJson: appointmentInfo.hasOwnProperty("insuranceDetailsJson") ? appointmentInfo.insuranceDetailsJson : { insuranceCompany: "", policyNumber: "", groupNumber: "" },
                feesDetailsJson: appointmentInfo.hasOwnProperty("feesDetailsJson") ? appointmentInfo.feesDetailsJson : { otherFees1: "0", otherFees2: "0", otherFees3: "0", otherFees4: "0", totalFees: "0", paymentMode: "Cash", transactionId: "" },
            });
            const otherFees = appointmentInfo?.otherFees?.split(",");
            if (appointmentInfo.hasOwnProperty("feesDetailsJson")) {
                let temp = [];
                if (appointmentInfo.feesDetailsJson.otherFees1 !== "0") {
                    temp = [...temp, "1"]
                }
                if (appointmentInfo.feesDetailsJson.otherFees2 !== "0") {
                    temp = [...temp, "2"]
                }
                if (appointmentInfo.feesDetailsJson.otherFees3 !== "0") {
                    temp = [...temp, "3"]
                }
                if (appointmentInfo.feesDetailsJson.otherFees4 !== "0") {
                    temp = [...temp, "4"]
                }
                setOtherFeesOptions(temp);
            }
            otherFees?.forEach((item, index) => {
                setInfo((prev) => {
                    return {
                        ...prev,
                        [`otherFees${index + 1}`]: item,
                    };
                });
                const otherFeesOptionsTemp = JSON.parse(
                    JSON.stringify(otherFeesOptions)
                );
                otherFeesOptionsTemp.push(index);
                // setOtherFeesOptions(otherFeesOptionsTemp);
            });
            // let tempFees = info.feesDetailsJson;
            // setOtherFees(info.feesDetailsJson);
            let k = Object.keys(info.feesDetailsJson);
            k = k.filter((e) => (e !== "totalFees" && e !== "paymentMode" && e !== "transactionId"));
            let t = k.map((e, i) => {
                return {
                    otherFeeLabel: e,
                    otherFeeValue: info.feesDetailsJson[e],
                    labelRef: createRef(),
                    valueRef: createRef()
                }
            })
            setOtherFees(t);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.info]);
    useEffect(() => {
        calculateTotalFee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info.fees]);

    const handleInsuranceInfoChange = (name, value) => {
        setInfo((prev) => {
            return {
                ...prev,
                insuranceDetailsJson: {
                    ...prev.insuranceDetailsJson,
                    [name]: value
                }
            }
        })
    }

    const handleChange = (name, value) => {
        setInfo((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleNextClick = () => {
        let validate = true;
        otherFees.forEach(e => {
            if (e.otherFeeLabel === "") {
                e.labelRef.current.focus();
                e.labelRef.current.style = "outline: 1px red solid"
                validate = false;
                return false;
            }
            if (e.otherFeeValue === "" || e.otherFeeValue === 0 || e.otherFeeValue === "0") {
                e.valueRef.current.focus();
                e.valueRef.current.style = "outline: 1px red solid"
                validate = false;
                return false;
            }
        })
        if (!validate)
            return;
        let otherFeesTemp = {};
        otherFees.forEach(e => {
            otherFeesTemp[e.otherFeeLabel] = e.otherFeeValue;
        })
        const payload = {
            ...props.regInfo,
            displayOrder: props.info.displayOrder,
            patientAge: parseInt(props.regInfo.patientAge) || parseInt(props.info.patientAge),
            insuranceDetails: props.regInfo.insuranceScreen === "No" ? null : props.regInfo.insuranceDetails,
            fees: parseInt(info.fees),
            paymentMode: info.paymentMode,
            transactionId: info.transactionId,
            remarks: props.regInfo.remarks || props.info.remarks || '',
            otherFees: otherFees.join(","),
            insuranceDetailsJson: info.insuranceDetailsJson,
            feesDetailsJson: { ...otherFeesTemp, totalFees: totalFee },
        };
        addNewOpdAppointment(payload).then((res) => {
            if (res.status === 200) {
                const currentDate = new Date();
                const appointmentDate = new Date(payload.appointmentDate);
                if (appointmentDate <= currentDate) {
                    getDoctorById(payload.doctorId).then((docRes) => {
                        const notification_payload = {
                            appointment_id: res?.result?.opdAppointmentRequestDto?.appointmentId,
                            user_id: docRes.result?.doctorDetails?.userId,
                            notification_message: `New appointment for ${docRes.result?.doctorDetails?.docName}`,
                            createdAt: serverTimestamp(),
                        }
                        const handleDeleteAppointment = async () => {
                            try {
                                const appointmentQuery = query(appointmentCollection, where("doctor_id", "==", props?.regInfo?.prevDoctorId));

                                const querySnapshot = await getDocs(appointmentQuery);

                                if (!querySnapshot.empty) {
                                    querySnapshot.forEach(async (doc) => {
                                        const doctorData = doc.data();
                                        const updatedAppointments = doctorData?.appointments?.filter((appointment) => parseInt(appointment.appointment_id) !== parseInt(res?.result?.opdAppointmentRequestDto?.appointmentId));
                                        if (updatedAppointments.length > 0) {
                                            await updateDoc(doc.ref, { doctor_id: parseInt(props?.regInfo?.prevDoctorId), doctor_name: doctorData.doctor_name, appointments: updatedAppointments });
                                        } else {
                                            await deleteDoc(doc.ref);
                                        }
                                    });
                                } else {
                                    console.log("No matching appointment found.");
                                }
                            } catch (error) {
                                console.error("Error deleting appointment:", error);
                            }
                        }

                        handleDeleteAppointment()

                        const handleSaveAppointment = async () => {
                            try {
                                const appointmentCollection = collection(db, 'appointments');
                                const doctorQuery = query(appointmentCollection, where('doctor_id', '==', payload.doctorId));

                                const querySnapshot = await getDocs(doctorQuery);

                                const appointment = {
                                    appointment_id: res?.result?.opdAppointmentRequestDto?.appointmentId,
                                    createdAt: new Date(),
                                    patient_name: `${props.regInfo?.firstName} ${props.regInfo?.lastName}`
                                };

                                if (!querySnapshot.empty) {
                                    const doctorDoc = querySnapshot.docs[0];
                                    const doctorDocRef = doctorDoc.ref;

                                    await updateDoc(doctorDocRef, {
                                        appointments: arrayUnion(appointment),
                                    });
                                } else {
                                    await addDoc(appointmentCollection, {
                                        doctor_id: payload.doctorId,
                                        doctor_name: docRes.result?.doctorDetails?.docName,
                                        appointments: [appointment],
                                    });
                                }

                                console.log('Appointment saved successfully!');
                            } catch (error) {
                                console.error('Error saving appointment:', error);
                            }
                        };

                        handleSaveAppointment()

                        const sendNotification = async () => {
                            try {
                                await addDoc(notificationsCollection, notification_payload);
                            } catch (error) {
                                console.log(error)
                            }
                        }

                        sendNotification()
                    }).catch((e) => {
                        console.log(e)
                    })
                }
                openModal();
            }
        });
    }

    const handleNextPrintClick = () => {
        let validate = true;
        otherFees.forEach(e => {
            if (e.otherFeeLabel === "") {
                e.labelRef.current.focus();
                e.labelRef.current.style = "outline: 1px red solid"
                validate = false;
                return false;
            }
            if (e.otherFeeValue === "" || e.otherFeeValue === 0 || e.otherFeeValue === "0") {
                e.valueRef.current.focus();
                e.valueRef.current.style = "outline: 1px red solid"
                validate = false;
                return false;
            }
        })
        if (!validate)
            return;
        let otherFeesTemp = {};
        otherFees.forEach(e => {
            otherFeesTemp[e.otherFeeLabel] = e.otherFeeValue;
        })
        let payload = {
            ...props.regInfo,
            displayOrder: props.info.displayOrder,
            patientAge: parseInt(props.regInfo.patientAge) || parseInt(props.info.patientAge),
            fees: parseInt(info.fees),
            paymentMode: info.paymentMode,
            transactionId: info.transactionId,
            remarks: props.regInfo.remarks || props.info.remarks || '',
            otherFees: otherFees.join(","),
            insuranceDetailsJson: info.insuranceDetailsJson,
            feesDetailsJson: { ...otherFeesTemp, totalFees: totalFee }
        };
        addNewOpdAppointment(payload).then((res) => {
            if (res.status === 200) {
                const currentDate = new Date();
                const appointmentDate = new Date(payload.appointmentDate);
                const aptData = res.result.opdAppointmentRequestDto;
                payload = {
                    ...payload,
                    appointmentId: aptData.appointmentId,
                    appointmentDate: aptData.appointmentDate.split(" ")[0],
                    appointmentTime: aptData.appointmentTime,
                    patientName: `${payload?.firstName} ${payload?.lastName}`,
                    priority: aptData.priority
                }
                getPatientById(payload.patientId).then((res1 => {
                    if (res1.status === 200) {
                        const patientInfo = res1.result.patientDto;
                        payload = {
                            ...payload,
                            patientAge: patientInfo.age,
                            patientGender: patientInfo.gender,
                            maritalStatus: patientInfo.maritalStatus,
                            patientOccupation: patientInfo.occupation
                        }
                        getDoctorById(payload.doctorId).then(res2 => {
                            if (res2.status === 200) {
                                const appointmentDate = new Date(payload.appointmentDate);
                                if (appointmentDate <= currentDate) {
                                    const notification_payload = {
                                        appointment_id: res?.result?.opdAppointmentRequestDto?.appointmentId,
                                        user_id: res2.result?.doctorDetails?.userId,
                                        notification_message: `New appointment for ${res2.result?.doctorDetails?.docName}`,
                                        createdAt: serverTimestamp(),
                                    }

                                    const handleDeleteAppointment = async () => {
                                        try {
                                            const appointmentQuery = query(appointmentCollection, where("doctor_id", "==", props?.regInfo?.prevDoctorId));
            
                                            const querySnapshot = await getDocs(appointmentQuery);
            
                                            if (!querySnapshot.empty) {
                                                querySnapshot.forEach(async (doc) => {
                                                    const doctorData = doc.data();
                                                    const updatedAppointments = doctorData?.appointments?.filter((appointment) => parseInt(appointment.appointment_id) !== parseInt(res?.result?.opdAppointmentRequestDto?.appointmentId));
                                                    if (updatedAppointments.length > 0) {
                                                        await updateDoc(doc.ref, { doctor_id: parseInt(props?.regInfo?.prevDoctorId), doctor_name: doctorData.doctor_name, appointments: updatedAppointments });
                                                    } else {
                                                        await deleteDoc(doc.ref);
                                                    }
                                                });
                                            } else {
                                                console.log("No matching appointment found.");
                                            }
                                        } catch (error) {
                                            console.error("Error deleting appointment:", error);
                                        }
                                    }
            
                                    handleDeleteAppointment()

                                    const handleSaveAppointment = async () => {
                                        try {
                                            const appointmentCollection = collection(db, 'appointments');
                                            const doctorQuery = query(appointmentCollection, where('doctor_id', '==', payload.doctorId));

                                            const querySnapshot = await getDocs(doctorQuery);

                                            const appointment = {
                                                appointment_id: res?.result?.opdAppointmentRequestDto?.appointmentId,
                                                createdAt: new Date(),
                                                patient_name: `${props.info?.firstName} ${props.info?.lastName}`
                                            };

                                            if (!querySnapshot.empty) {
                                                const doctorDoc = querySnapshot.docs[0];
                                                const doctorDocRef = doctorDoc.ref;

                                                await updateDoc(doctorDocRef, {
                                                    appointments: arrayUnion(appointment),
                                                });
                                            } else {
                                                await addDoc(appointmentCollection, {
                                                    doctor_id: payload.doctorId,
                                                    doctor_name: res2.result?.doctorDetails?.docName,
                                                    appointments: [appointment],
                                                });
                                            }
                                        } catch (error) {
                                            console.error('Error saving appointment:', error);
                                        }
                                    };

                                    handleSaveAppointment()

                                    const sendNotification = async () => {
                                        try {
                                            await addDoc(notificationsCollection, notification_payload);
                                        } catch (error) {
                                            console.log(error)
                                        }
                                    }

                                    sendNotification()
                                }
                                payload = { ...payload, doctorName: res2.result.doctorDetails.docName };
                                sessionStorage.setItem("pdfData", JSON.stringify(payload))
                                window.open("/opdReportPdf");
                                openModal();
                            }
                        })
                    }
                }));
            }
        });
    }

    const handleBackClick = () => {
        if (props.regInfo.insuranceScreen === "No") {
            props.goToStep(1);
            setInsuranceScreen(0);
        } else {
            props.goToStep(2);
        }
    };

    const calculateTotalFee = () => {
        let temp = parseFloat(info.fees);
        otherFees.forEach(element => {
            temp += parseFloat(element.otherFeeValue);
        });
        setTotalFee(isNaN(temp) ? 0 : temp);
    }

    return (
        <>
            <Modal
                isVisible={isModalVisible}
                onClose={closeModal}
            >
                <div className="flex justify-center mb-4">
                    <i className="far fa-check-circle fa-2x text-[#118C42] "></i>
                </div>
                <p className="text-center mb-4">OPD Appointment Booked Successfully.</p>
                <div className="flex justify-center">
                    <SuccessBtn
                        text="DONE"
                        onClick={() => {
                            closeModal();
                            navigate("/opd-appointment/manage");
                        }}
                    />
                </div>
            </Modal>


            <div className="p-5 lg:px-[30px] lg:pb-9 lg:pt-6">
                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-6 xl:gap-9">
                    <div className="">
                        <TextInput
                            label="Fees"
                            leftIcon={<span>₹</span>}
                            onChange={handleChange}
                            value={info.fees}
                            name="fees"
                        />
                        <Dropdown
                            label="Payment Mode"
                            placeholder="Payment Mode"
                            options={paymentModeOptions}
                            value={info.feesDetailsJson.paymentMode}
                            onChange={(val) => {
                                setInfo((prev) => {
                                    return {
                                        ...prev,
                                        feesDetailsJson: {
                                            ...prev.feesDetailsJson,
                                            paymentMode: val.value
                                        }
                                    }
                                })
                            }}
                        />
                        <TextInput
                            label="Cheque No / Transaction ID"
                            placeholder="Cheque No / Transaction ID"
                            value={info.feesDetailsJson.transactionId}
                            name="transactionId"
                            onChange={(name, val) => {
                                setInfo((prev) => {
                                    return {
                                        ...prev,
                                        feesDetailsJson: {
                                            ...prev.feesDetailsJson,
                                            [name]: val
                                        }
                                    }
                                });
                            }}
                        />
                        <TextArea
                            label="Remark"
                            rows={3}
                            value={info.remarks}
                            name="remarks"
                            onChange={handleChange}
                        />
                    </div>
                    <OtherFeesSection
                        otherFees={otherFees}
                        setOtherFees={setOtherFees}
                        mainFee={info.fees}
                        totalFee={totalFee}
                        setTotalFee={setTotalFee}
                        calculateTotalFee={calculateTotalFee}
                    />
                </div>
                <div className="flex flex-col md:flex-row md:items-center justify-between md:mt-10 gap-5 md:gap-0">
                    <div>
                        <Button
                            text="Previous"
                            textStyle="mx-5 md:mx-10"
                            onClick={handleBackClick}
                        />
                    </div>
                    <div className="flex md:items-center gap-2 sm:gap-7">
                        <Button
                            text="Book Appointment"
                            textStyle="mx-3 md:mx-10"
                            onClick={handleNextClick}
                        />
                        <Button
                            text="Book Appointment And Print"
                            textStyle="mx-3 md:mx-10"
                            onClick={handleNextPrintClick}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaymentInformation;
